import React from "react"
import { Link, navigate } from "gatsby"
import axios from 'axios'

import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import { createGlobalStyle } from "styled-components"
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip';

import CircularProgress from '@material-ui/core/CircularProgress'
import TowerGothic from "../fonts/TowerGothic.otf"

const TitleStyle = createGlobalStyle`
  @font-face {
    font-family: 'TowerGothic';
    src: url(${TowerGothic}) format('truetype');
  }
`
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  appbar: {
    background: '#fff',
    borderBottom: 'medium solid',
    borderImage: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    borderImageSlice: '1',
    borderTop: '0',
    borderLeft: '0',
    borderRight: '0',
  },
  link: {
    boxShadow: "none",
    marginRight: theme.spacing(2),
  },
  logo: {
    marginBottom: '0',
    height: '38px',
  },
  title: {
    fontFamily: "Merriweather,Georgia,serif",
    flexGrow: 3,
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
    color: '#222'
  },
  search: {
    flexGrow: 1,
    borderRadius: theme.shape.borderRadius,
    marginLeft: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      width: '10%',
    },
  },
}))

const Header = ({ title }) => {
  const classes = useStyles()

  const [open, setOpen] = React.useState(false)
  const [options, setOptions] = React.useState([])
  const loading = open && options.length === 0

  React.useEffect(() => {
    let active = true

    if (!loading) {
      return undefined
    }

    (async () => {
      const response = await axios.get('/search.json')
      if (active) {
        setOptions(response.data)
      }
    })()

    return () => {
      active = false
    }
  }, [loading])

  React.useEffect(() => {
    if (!open) {
      setOptions([])
    }
  }, [open])

  let onSearch = (e, v) => {
    if (v !== null) {
      navigate(v.path)
    }
  }

  return (
    <div className={classes.root}>
      <TitleStyle />
      <AppBar className={classes.appbar} position="static" >
        <Toolbar>

          <Tooltip title="黒毛和牛の牝牛をクリックしてトップに戻る" arrow>
            <Link to='/' className={classes.link}>
              <img className={classes.logo} src="/logo.png" alt={title}/>
            </Link>
          </Tooltip>
          <Typography variant="h6" className={classes.title}>
            {title}
          </Typography>
          <div className={classes.search}>
            <Autocomplete
              id="search"
              freeSolo
              style={{ width: "100%" }}
              size="small"
              open={open}
              onOpen={() => {
                setOpen(true);
              }}
              onClose={() => {
                setOpen(false);
              }}
              getOptionSelected={(option, value) => option.name === value.title}
              getOptionLabel={(option) => option.title}
              options={options}
              loading={loading}
              onChange={onSearch}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search"
                  variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />

          </div>
        </Toolbar>
      </AppBar>
    </div>
  )
}

export default Header