import React from "react"
import { makeStyles } from '@material-ui/core/styles'

import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(2),
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    backgroundColor: "white",
    borderTop: 'medium solid',
    borderImage: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    borderImageSlice: '1',
    borderBottom: '0',
    borderLeft: '0',
    borderRight: '0',
    textAlign: 'center',
  },
}))

const Footer = ({ title }) => {
  const classes = useStyles()

  return (
    <footer className={classes.root}>
      <Container>
        <Typography variant="body2" color="textSecondary" gutterBottom>
          COPYRIGHT © {new Date().getFullYear()}
          {` `}
          <a href="https://yakiniku-u.ukai-mnap.co.jp" rel="noopener noreferrer" target="_blank">特選黒毛和牛牝牛 焼肉 u</a>. ALL RIGHTS RESERVED.
          POWERD BY
          {` `}
          <a href="https://ukai-mnap.co.jp" rel="noopener noreferrer" target="_blank">UKAI CO., LTD.</a>
        </Typography>
      </Container>
    </footer>
  )
}

export default Footer
