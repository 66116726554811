import React from "react"

import CssBaseline from '@material-ui/core/CssBaseline'
import Container from '@material-ui/core/Container'

import Header from "../components/header"
import Footer from "../components/footer"

import { createGlobalStyle } from "styled-components";
const GlobalStyle = createGlobalStyle`
  html,
  body {
    background-color: #323232;
  }
  img {
    margin-bottom: 0;
  }
  .embedVideo-container {
    overflow: hidden;
    position: relative;
    width:100%;
  }
  .embedVideo-container::after {
      padding-top: 56.25%;
      display: block;
      content: '';
  }
  .embedVideo-container iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
  }
  button.gradient {
    background: linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%);
    border: 0;
    border-radius: 3px;
    box-shadow: 0 3px 5px 2px rgba(255, 105, 135, .3);
    color: white;
    height: 48px;
    padding: 0 30px;
  }
  button.gradient-small {
    background: linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%);
    border: 0;
    border-radius: 3px;
    box-shadow: 0 3px 5px 2px rgba(255, 105, 135, .3);
    color: white;
    height: 38px;
    padding: 0 30px;
  }
  .gradient-button-label {
    width: 100%;
    display: inherit;
    align-items: inherit;
    justify-content: inherit;
  }
  .gradient-button-icon {
    width: 30px;
    height: 30px;
  }
`

const Layout = ({ location, title, children }) => {
  return (
    <div>
      <CssBaseline />
      <GlobalStyle />
      <Header title={title} />
      <Container>
        {children}
      </Container>
      <Footer title={title} />
    </div>
  )
}

export default Layout
